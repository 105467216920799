import {Link} from "react-router-dom";

const Homepage = () => {
    return (
        <>
            <section className="homepage">
                <nav className="menu-home">
                    <Link to="/duy-x-yen" className="btn">Hành trình của Duy và Yến</Link>
                </nav>
            </section>
        </>
    );
};

export default Homepage;