import {Link} from "react-router-dom";

const DuyAndYen = () => {
    const startDate = new Date('2024-02-18');
    const currentDate = new Date();
    const timeDiff = currentDate.valueOf() - startDate.valueOf();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return (
        <>
            <section className="duy-yen">
                <div className="wrapper">
                    <h1>Duy và Yến đã yêu nhau được {daysDiff} ngày rồi đó!</h1>
                    <h1>╰(*°▽°*)╯</h1>
                    <h1>＼(((￣(￣(￣▽￣)￣)￣)))／</h1>
                    <Link to="/" className="btn">Trang chủ</Link>
                </div>
            </section>
        </>
    );
};

export default DuyAndYen;